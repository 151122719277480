'use client';

import Image from 'next/image';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useState } from 'react';
import LossProtectionCard from './LossProtectionCard';
import PositiveSlippage from './PositiveSlippage';
import LiquidityProvider from './LiquidityProvider';
import LiquidityProviderType from './LiquidityProviderType';
import SDK from '../SDK';
const Features = () => {
  const [isHovered, setIsHovered] = useState(false);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const rotateX = useTransform(mouseY, [-300, 300], [10, -10]);
  const rotateY = useTransform(mouseX, [-300, 300], [-10, 10]);
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    mouseX.set(e.clientX - centerX);
    mouseY.set(e.clientY - centerY);
  };
  return <div className="py-16 md:py-[128px] flex flex-col items-center" data-sentry-component="Features" data-sentry-source-file="Features.tsx">
      <p className="text-center text-sm font-medium text-[#DDABF8] uppercase">THE AVANTIS EDGE</p>
      <h2 className="text-center mt-4 text-[36px] md:text-[52px] font-medium">
        Scalable leverage for everyone
      </h2>
      <div className="relative mt-12 mx-4 md:mx-0 flex justify-center">
        <div className="absolute md:w-[986px] md:h-[657px] w-[300px] h-[200px] rounded-full bg-[#D586FF] filter blur-[100px]"></div>
        <motion.div className="relative perspective-1000" onMouseMove={handleMouseMove} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{
        rotateX: isHovered ? rotateX : 0,
        rotateY: isHovered ? rotateY : 0,
        transition: 'all 0.1s ease'
      }} data-sentry-element="unknown" data-sentry-source-file="Features.tsx">
          <Image className="relative z-10" src="/images/landing/features/app-screenshot.svg" alt="feature-1" width={1090} height={710} data-sentry-element="Image" data-sentry-source-file="Features.tsx" />
        </motion.div>
      </div>
      <div className="grid md:grid-cols-2 max-w-[1320px] px-2 md:px-0 gap-8 mt-16 w-full">
        <LossProtectionCard data-sentry-element="LossProtectionCard" data-sentry-source-file="Features.tsx" />
        <PositiveSlippage data-sentry-element="PositiveSlippage" data-sentry-source-file="Features.tsx" />
        <LiquidityProvider data-sentry-element="LiquidityProvider" data-sentry-source-file="Features.tsx" />
        <LiquidityProviderType data-sentry-element="LiquidityProviderType" data-sentry-source-file="Features.tsx" />
      </div>
      <div className="my-16 max-w-[1320px] w-full">
        <SDK data-sentry-element="SDK" data-sentry-source-file="Features.tsx" />
      </div>
    </div>;
};
export default Features;