'use client';

import { PairIcon } from '@/components/trade/pairSelector/PairLabel';
import usePairs from '@/hooks/pairs/usePairs';
import useLastDayPythPrice from '@/hooks/pyth/useLastDayPythPrice';
import useLatestPrice from '@/hooks/pyth/useLatestPrice';
import usePythPrices from '@/hooks/pyth/usePythPrices';
import { roundDownTrim } from '@/lib/numbers';
import { cn, numberWithCommas } from '@/lib/utils';
import { usePairStore } from '@/store/pairs';
import Marquee from 'react-fast-marquee';
const getAssetType = (groupIndex: number) => {
  switch (groupIndex) {
    case 2:
      return 'Forex';
    case 3:
      return 'Commodities';
    default:
      return 'Crypto';
  }
};
const assetList = [
// 'ETH/USD',
// 'BTC/USD',
// 'SOL/USD',
'XAU/USD', 'XAG/USD', 'USD/JPY', 'USD/EUR', 'AERO/USD'];
const PriceMarquee = () => {
  usePythPrices();
  const {
    pairs
  } = usePairs();
  const {
    latestPrice
  } = useLatestPrice();
  const prices = usePairStore(state => state.pairCurrentPricePure);
  const {
    priceDelta
  } = useLastDayPythPrice();
  return <div className="relative overflow-hidden w-full" data-sentry-component="PriceMarquee" data-sentry-source-file="PriceMarquee.tsx">
      <Marquee autoFill speed={100} data-sentry-element="Marquee" data-sentry-source-file="PriceMarquee.tsx">
        {pairs.filter(pair => assetList.includes(`${pair.from}/${pair.to}`)).map(pair => {
        const price = prices?.[pair._index]?.price || latestPrice?.[pair._index]?.price || null;
        const priceChange = priceDelta?.[pair._index] || null;
        return <div key={pair._index} className="mx-2 p-4 flex items-center gap-4 bg-[#0E0E134D] rounded-md min-w-[280px] h-[115px]">
                <PairIcon pairInfo={pair} size="size-[60px]" />
                <div>
                  <p className="font-medium">
                    {pair.from}-{pair.to}
                  </p>
                  <p className="text-xs text-grey-100 font-medium">
                    {getAssetType(pair.groupIndex)}
                  </p>
                  <div className="flex items-center gap-2 font-fono">
                    <span>{price ? `$${numberWithCommas(price)}` : '--'}</span>
                    <span className={cn(priceChange ? priceChange > 0 ? 'text-primary-100' : 'text-error-100' : 'text-white')}>
                      {priceChange ? `${roundDownTrim(priceChange, 2)}%` : ''}
                    </span>
                  </div>
                </div>
              </div>;
      })}
      </Marquee>
      <div className="z-20 absolute inset-y-0 left-0 w-full bg-gradient-to-r from-[#0E0E134D] via-transparent to-[#0E0E134D] [background-position:_10%_0,_90%_0] pointer-events-none"></div>
    </div>;
};
export default PriceMarquee;