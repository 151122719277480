'use client';

import { socialLinks } from '@/constants/landing';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import axios from 'axios';
import { Loader } from 'lucide-react';
import useAppToast from '@/hooks/shared/useAppToast';
const footerLinks = [{
  name: 'Launch App',
  link: '/trade'
}, {
  name: 'Docs',
  link: 'https://docs.avantisfi.com/',
  isExternal: true
}, {
  name: 'Avantis SDK',
  link: 'https://sdk.avantisfi.com',
  isExternal: true
}, {
  name: 'Terms of Service',
  link: '/docs/tos'
}, {
  name: 'Privacy Policy',
  link: '/docs/privacy'
}];
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [error, setError] = useState('');
  const {
    info,
    error: toastError
  } = useAppToast();
  useEffect(() => {
    setError('');
  }, [email]);
  const handleSubscribe = async () => {
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }
    setIsSubscribing(true);
    try {
      await axios.post('/api/subscribe', {
        email
      });
      info('Subscription successful', 'You have been subscribed to the newsletter');
      setEmail('');
    } catch (error) {
      toastError('Subscription failed', (error as any)?.response?.data?.error || 'Please try again later');
    } finally {
      setIsSubscribing(false);
    }
  };
  return <div className="relative z-30 pt-[72px] pb-[128px] px-4 md:px-[60px] rounded-t-3xl grid md:grid-cols-3 gap-8 bg-[rgba(8,8,14)]" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="space-y-4 order-2 md:order-1">
        <Image className="py-1" src="/images/avantis-logo.svg" alt="brand" width={98} height={18} data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
        <p className="text-grey-100 leading-none text-xs">© 2024 Avantis. All rights reserved.</p>
      </div>
      <div className="space-y-3 md:mx-auto text-sm order-3 md:order-2">
        {footerLinks.map(link => <Link className="flex" key={link.name} href={link.link} target={link.isExternal ? '_blank' : '_self'}>
            {link.name}
          </Link>)}
      </div>
      <div className="flex flex-col gap-4 md:items-end order-1 md:order-3">
        <p className="text-grey-100">Subscribe and get the latest updates from Avantis</p>
        <div className="flex gap-2 px-4 py-2 bg-grey-600 rounded-md w-[326px]">
          <Input type="email" placeholder="Enter your email" className="h-full placeholder:text-grey-300-disabled-text" value={email} onChange={e => setEmail(e.target.value)} data-sentry-element="Input" data-sentry-source-file="Footer.tsx" />
          <Button variant="long" onClick={handleSubscribe} className="min-w-[105px]" disabled={!email || isSubscribing || !!error} data-sentry-element="Button" data-sentry-source-file="Footer.tsx">
            {isSubscribing ? <Loader className="animate-spin" /> : 'Subscribe'}
          </Button>
        </div>
        {!!error && <p className="text-error-100 text-sm">{error}</p>}
        <div className="flex *:h-fit gap-4">
          {socialLinks.map(link => <Link className="flex size-6" key={link.name} href={link.link} target="_blank" rel="noopener noreferrer">
              <Image src={link.icon} alt={link.name} width={24} height={24} />
            </Link>)}
        </div>
      </div>
    </div>;
};
export default Footer;