import React from 'react';
import AnimatedCard from './AnimatedCard';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useMediaQuery } from 'usehooks-ts';
const LiquidityProviderType = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return <AnimatedCard data-sentry-element="AnimatedCard" data-sentry-component="LiquidityProviderType" data-sentry-source-file="LiquidityProviderType.tsx">
      <motion.div className="relative h-full" data-sentry-element="unknown" data-sentry-source-file="LiquidityProviderType.tsx">
        <div className="px-4 md:px-[60px] py-6 md:py-12 space-y-4">
          <p className="text-[#DDABF8] text-sm font-medium leading-none">LIQUIDITY PROVIDER</p>
          <p className="text-[32px] font-medium leading-none">Built for every type of LP</p>
          <p className="text-grey-100 leading-[24px]">
            Avantis unlocks a new design space for LPs via our unique time and risk parameters.
            Choose to be on the lowest or highest end of the risk-spectrum, based on your view of
            the market.
          </p>
        </div>
        <div className="mx-4 md:mx-[60px] mb-12 h-[324px] relative flex items-center justify-center">
          <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full py-9 px-5 rounded-[20px] border opacity-50 bg-[linear-gradient(180deg,rgba(138,66,168,0.1)_0%,rgba(155,81,186,0.1)_100%)]">
            <div className="absolute top-6 bottom-6 left-4 right-4 rounded-[20px] border opacity-50 bg-[linear-gradient(180deg,rgba(138,66,168,0.1)_0%,rgba(155,81,186,0.1)_100%)]"></div>
          </div>

          <Image className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-20 group-hover:translate-y-[-70%] transition-all duration-300" src="/images/landing/features/liquidity/junior.svg" alt="junior" width={isMobile ? 300 : 402} height={isMobile ? 140 : 185} data-sentry-element="Image" data-sentry-source-file="LiquidityProviderType.tsx" />

          <Image className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-30 group-hover:translate-y-[-25%] transition-all duration-300" src="/images/landing/features/liquidity/senior.svg" alt="senior" width={isMobile ? 300 : 465} height={isMobile ? 140 : 191} data-sentry-element="Image" data-sentry-source-file="LiquidityProviderType.tsx" />
        </div>
      </motion.div>
    </AnimatedCard>;
};
export default LiquidityProviderType;