'use client';

import Image from 'next/image';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { strategyTabs, strategyTabsType } from '@/constants/landing';
import { Line, ResponsiveContainer, XAxis, YAxis, LineChart, Tooltip } from 'recharts';
import { useMemo, useState } from 'react';
import { strategyData } from './constants';
import { format } from 'date-fns';
import { roundDownTrim } from '@/lib/numbers';
import { cn } from '@/lib/utils';
import InfoPanel from './InfoPanel';
const timeFrames = [{
  key: '6m',
  label: '6 Months',
  value: 180
}, {
  key: '1y',
  label: '1 Year',
  value: 360
}, {
  key: '2y',
  label: '2 Years',
  value: 720
}];
const Strategy = () => {
  const [selectedTab, setSelectedTab] = useState(strategyTabs[0]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(timeFrames[0]);
  const chartData = useMemo(() => {
    if (selectedTab.key === strategyTabsType.METALS_X_CRYPTO) {
      return {
        keys: [{
          key: 'gold',
          name: 'XAU-USD'
        }, {
          key: 'btcUsd',
          name: 'BTC-USD'
        }, {
          key: 'correlation3m',
          name: '3M Correlation'
        }],
        data: strategyData.map(item => ({
          date: item.date,
          gold: roundDownTrim(+item.gold),
          btcUsd: roundDownTrim(+item.btc),
          correlation3m: item.gold_btc_corr ? roundDownTrim(+item.gold_btc_corr) : null
        }))
      };
    }
    if (selectedTab.key === strategyTabsType.OIL_X_CRYPTO) {
      return {
        keys: [{
          key: 'crude',
          name: 'WTI-USD'
        }, {
          key: 'btcUsd',
          name: 'BTC-USD'
        }, {
          key: 'correlation3m',
          name: '3M Correlation'
        }],
        data: strategyData.map(item => ({
          date: item.date,
          crude: roundDownTrim(+item.crude),
          btcUsd: roundDownTrim(+item.btc),
          correlation3m: item.crude_btc_corr ? roundDownTrim(+item.crude_btc_corr) : null
        }))
      };
    }
    return {
      keys: [{
        key: 'jpy',
        name: 'USD-JPY'
      }, {
        key: 'ethUsd',
        name: 'ETH-USD'
      }, {
        key: 'correlation3m',
        name: '3M Correlation'
      }],
      data: strategyData.map(item => ({
        date: item.date,
        jpy: item.jpy ? roundDownTrim(+item.jpy) : null,
        ethUsd: roundDownTrim(+item.eth),
        correlation3m: item.eth_jpy_corr ? roundDownTrim(+item.eth_jpy_corr) : null
      }))
    };
  }, [selectedTab]);
  const selectedPeriodData = chartData.data.slice(-selectedTimeFrame.value);
  const sixMonthsData: any = chartData.data.slice(-180);
  const oneYearData: any = chartData.data.slice(-360);
  const key1Returns = {
    '6m': (sixMonthsData[sixMonthsData.length - 1][chartData.keys[0].key] - sixMonthsData[0][chartData.keys[0].key]) / sixMonthsData[0][chartData.keys[0].key] * 100,
    '1y': (oneYearData[oneYearData.length - 1][chartData.keys[0].key] - oneYearData[0][chartData.keys[0].key]) / oneYearData[0][chartData.keys[0].key] * 100
  };
  const key2Returns = {
    '6m': (sixMonthsData[sixMonthsData.length - 1][chartData.keys[1].key] - sixMonthsData[0][chartData.keys[1].key]) / sixMonthsData[0][chartData.keys[1].key] * 100,
    '1y': (oneYearData[oneYearData.length - 1][chartData.keys[1].key] - oneYearData[0][chartData.keys[1].key]) / oneYearData[0][chartData.keys[1].key] * 100
  };
  const yDomain = useMemo(() => {
    const values: number[] = chartData.data.flatMap((d: any) => [d[chartData.keys[0].key], d[chartData.keys[1].key]]);
    const min = Math.floor(Math.min(...values) / 5) * 5 - 50;
    const max = Math.ceil(Math.max(...values) / 5) * 5 + 50;
    return [min, max];
  }, [chartData]);
  const CustomTooltip = ({
    active,
    payload
  }: any) => {
    if (active && payload && payload.length) {
      const dataItem = payload[0].payload;
      return <div className="bg-secondary border-none shadow-md">
          <div className="p-4 text-sm">
            <p className="text-sm font-semibold">{format(new Date(dataItem.date), 'd MMM')}</p>
            <p className="font-bold">
              {chartData.keys[0].name}: {roundDownTrim(dataItem[chartData.keys[0].key] ?? 0)}
            </p>
            <p className="font-bold">
              {chartData.keys[1].name}: {roundDownTrim(dataItem[chartData.keys[1].key] ?? 0)}
            </p>
            <p className="font-bold">
              {chartData.keys[2].name}: {roundDownTrim(dataItem[chartData.keys[2].key] ?? 0)}
            </p>
          </div>
        </div>;
    }
    return null;
  };
  return <div className="px-4 md:px-[60px] py-[72px] md:py-[128px] space-y-8 md:space-y-0" data-sentry-component="Strategy" data-sentry-source-file="Strategy.tsx">
      <div className="flex gap-4 md:gap-12 h-[273px]">
        <div className="w-[6px] h-full bg-gradient-to-r from-purple-300 to-transparent relative" style={{
        clipPath: 'polygon(50% 100%, 0 0, 100% 0)'
      }} />
        <div>
          <h3 className="text-[32px] md:text-[52px] font-medium">One DEX, Infinite Strategies. </h3>
          <p className="mt-4 text-[24px] md:text-4xl text-grey-100">
            Maximize your alpha - pair crypto leverage with uncorrelated real-world assets, while
            retaining custody of your collateral.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-[1fr_1.2fr] gap-8 md:gap-0 relative rounded-l-md">
        <div className="hidden md:block absolute top-0 left-0 right-0 h-[122px] bg-[#62178A] filter blur-[100px]"></div>
        <div className="hidden md:block absolute h-[342px] top-0 left-0 right-0 filter blur-[50px] overflow-hidden">
          <Image className="" src="/images/landing/strategy/card-bg.svg" alt="card-bg" width={1339} height={272} data-sentry-element="Image" data-sentry-source-file="Strategy.tsx" />
        </div>
        <div className="h-full w-full md:bg-gradient-to-b from-[#0e0e13d9] via-[#0e0e13d9] to-transparent rounded-l-xl z-20 md:px-6 md:py-8 overflow-hidden">
          <Tabs value={selectedTab.key} className="w-fit bg-transparent rounded-lg" data-sentry-element="Tabs" data-sentry-source-file="Strategy.tsx">
            <TabsList className="h-48 md:h-12 bg-transparent gap-2 flex flex-col md:flex-row md:justify-start" data-sentry-element="TabsList" data-sentry-source-file="Strategy.tsx">
              {strategyTabs.map(tab => <TabsTrigger key={tab.key} value={tab.key} className="p-4 h-12 w-full md:w-fit bg-grey-600 rounded-full data-[state=active]:bg-grey-400 font-normal text-lg" onClick={() => setSelectedTab(tab)}>
                  {tab.label}
                </TabsTrigger>)}
            </TabsList>
            <TabsContent value={strategyTabsType.METALS_X_CRYPTO} className="px-4 md:p-6" data-sentry-element="TabsContent" data-sentry-source-file="Strategy.tsx">
              <InfoPanel key1={chartData.keys[0].name} key2={chartData.keys[1].name} key1Returns={key1Returns} key2Returns={key2Returns} description="Metals like Gold (XAU) often retain or increase their value during times of market turbulence or economic uncertainty." data-sentry-element="InfoPanel" data-sentry-source-file="Strategy.tsx" />
            </TabsContent>
            <TabsContent value={strategyTabsType.OIL_X_CRYPTO} className="px-4 md:p-6" data-sentry-element="TabsContent" data-sentry-source-file="Strategy.tsx">
              <InfoPanel key1={chartData.keys[0].name} key2={chartData.keys[1].name} key1Returns={key1Returns} key2Returns={key2Returns} description="Oil and Bitcoin have historically shown a negative correlation, allowing investors to hedge against market volatility by investing in both assets." data-sentry-element="InfoPanel" data-sentry-source-file="Strategy.tsx" />
            </TabsContent>
            <TabsContent value={strategyTabsType.FOREX_X_CRYPTO} className="px-4 md:p-6" data-sentry-element="TabsContent" data-sentry-source-file="Strategy.tsx">
              <InfoPanel key1={chartData.keys[0].name} key2={chartData.keys[1].name} key1Returns={key1Returns} key2Returns={key2Returns} description="USD-JPY is one of the most popular and volatile forex pairs, affected by geopolitical catalysts, U.S / Japanese monetary policies and dollar-yen trade flows." data-sentry-element="InfoPanel" data-sentry-source-file="Strategy.tsx" />
            </TabsContent>
          </Tabs>
        </div>
        <div className="bg-gradient-to-b from-[#0e0e13d9] via-[#0e0e13d9] to-transparent rounded-r-xl z-20 px-4 md:px-6 py-4 md:py-8">
          <div className="grid md:grid-cols-2 gap-4">
            <div className="md:ml-8 flex items-center gap-4 text-xs md:text-sm order-2 md:order-1">
              <div className="flex items-center gap-2">
                <div className="w-5 h-3 bg-[#E697FF]"></div>
                <p className="whitespace-nowrap">{chartData.keys[0].name}</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-5 h-3 bg-[#9D61FA]"></div>
                <p className="whitespace-nowrap">{chartData.keys[1].name}</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-5 h-3 bg-[#A8ACB8]"></div>
                <p className="whitespace-nowrap">{chartData.keys[2].name}</p>
              </div>
            </div>
            <div className="flex items-center gap-2 justify-end order-1 md:order-2">
              {timeFrames.map(t => <div role="button" key={t.key} className={cn('text-grey-200 bg-grey-600 rounded-full text-sm md:text-base px-4 py-2 flex-1 md:flex-none whitespace-nowrap', selectedTimeFrame.key === t.key && 'bg-grey-400 text-grey-000')} onClick={() => setSelectedTimeFrame(t)}>
                  {t.label}
                </div>)}
            </div>
          </div>

          <div className="max-h-[460px] mt-6 space-y-4">
            <p className="text-center text-grey-100">
              Indexed Price performance vs rolling correlation
            </p>
            <ResponsiveContainer width="100%" height={400} data-sentry-element="ResponsiveContainer" data-sentry-source-file="Strategy.tsx">
              <LineChart data={selectedPeriodData} margin={{
              right: -50,
              left: -50
            }} data-sentry-element="LineChart" data-sentry-source-file="Strategy.tsx">
                <XAxis dataKey="date" className="text-xs" tick={false}
              // tickFormatter={(value) => {
              //   const [year, month, day] = value.split('-').map(Number);
              //   const date = new Date(year, month - 1, day);
              //   return format(date, 'MMM dd');
              // }}
              data-sentry-element="XAxis" data-sentry-source-file="Strategy.tsx" />
                <YAxis tick={false} yAxisId="left" className="text-xs" domain={yDomain} data-sentry-element="YAxis" data-sentry-source-file="Strategy.tsx" />
                <YAxis tick={false} yAxisId="right" orientation="right" domain={[-1, 1]} className="text-xs" data-sentry-element="YAxis" data-sentry-source-file="Strategy.tsx" />
                <Tooltip content={<CustomTooltip />} data-sentry-element="Tooltip" data-sentry-source-file="Strategy.tsx" />

                <Line yAxisId="left" type="natural" dataKey={chartData.keys[0].key} stroke="#E697FF" name={chartData.keys[0].name} strokeWidth={2} dot={false} data-sentry-element="Line" data-sentry-source-file="Strategy.tsx" />
                <Line yAxisId="left" type="natural" dataKey={chartData.keys[1].key} stroke="#9D61FA" name={chartData.keys[1].name} strokeWidth={2} dot={false} data-sentry-element="Line" data-sentry-source-file="Strategy.tsx" />
                <Line yAxisId="right" type="monotone" strokeWidth={2} strokeDasharray="5 5" dataKey={chartData.keys[2].key} stroke="#A8ACB8" name={chartData.keys[2].name} dot={false} data-sentry-element="Line" data-sentry-source-file="Strategy.tsx" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>;
};
export default Strategy;