import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
const SDK = () => {
  return <div className="relative" data-sentry-component="SDK" data-sentry-source-file="SDK.tsx">
      <div className="absolute inset-0 rounded-3xl p-[1px] bg-gradient-to-b from-[#27232D] to-transparent">
        <div className="h-full w-full bg-grey-800 rounded-[23px]"></div>
      </div>
      <Image className="hidden md:block absolute top-0 left-0" src="/images/landing/sdk/purple-grid.svg" alt="partners" width={392} height={257} data-sentry-element="Image" data-sentry-source-file="SDK.tsx" />
      <Image className="absolute top-0 right-0" src="/images/landing/sdk/purple-grid.svg" alt="partners" width={392} height={257} data-sentry-element="Image" data-sentry-source-file="SDK.tsx" />
      <div className="relative z-20 px-[60px] py-[40px] space-y-4 text-center">
        <h3 className="text-[32px] font-medium leading-0">Build with Avantis SDK</h3>
        <p className="text-grey-100 sm:max-w-[466px] mx-auto">
          Power your platform with Avantis SDK — seamlessly access real-time price feeds, execute
          fast trades, and manage positions, empowering builders and traders for a competitive edge.
        </p>
        <Link className="block text-primary-200 font-medium" href="https://sdk.avantisfi.com/" target="_blank" data-sentry-element="Link" data-sentry-source-file="SDK.tsx">
          Learn about Avantis SDK
        </Link>
      </div>
    </div>;
};
export default SDK;