/* eslint-disable react/no-array-index-key */
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { cn, nFormatUsd } from '@/lib/utils';
import { roundDownTrim } from '@/lib/numbers';
import usePrevious from '@/hooks/shared/usePrevious';

// Adjusts width of individual narrow digits
const calculateDigitWidth = (digit: string): string => {
  switch (digit) {
    case '1':
      return '50%';
    case '7':
      return '80%';
    default:
      return '100%';
  }
};

// Creates array of digits to vertically scroll through
const formatForDisplay = (number: number, includeDecimals: boolean, showAs: 'dollar' | 'percent'): string[] => {
  const decimals = includeDecimals ? 2 : 0;
  if (isNaN(number)) return Number(0).toFixed(decimals)?.split('').reverse();
  return showAs === 'dollar' ? nFormatUsd(roundDownTrim(number))?.split('').reverse() : `${number > 0 ? '+' : '-'}${roundDownTrim(number)}%`.split('').reverse();
};
interface DecimalColumnProps {
  fontSize: string;
  color: string;
  value: string;
}

// Render decimals
const DecimalColumn: React.FC<DecimalColumnProps> = ({
  fontSize,
  color,
  value
}) => {
  return <div data-sentry-component="DecimalColumn" data-sentry-source-file="AnimatedCounter.tsx">
      <span className={cn('font-medium align-top font-mono', 'h-auto')} style={{
      fontSize,
      lineHeight: fontSize,
      color
    }}>
        {value}
      </span>
    </div>;
};
interface NumberColumnProps {
  digit: number;
  delta: string | null;
  fontSize: string;
  color: string;
  letterSpacing: number;
  showAs?: 'dollar' | 'percent';
}

// Render numbers
const NumberColumn: React.FC<NumberColumnProps> = ({
  digit,
  delta,
  fontSize,
  color,
  letterSpacing
}) => {
  const [position, setPosition] = useState(0);
  const [width, setWidth] = useState('auto');
  const columnContainer = useRef<HTMLDivElement>(null);
  const digitRef = useRef<(HTMLSpanElement | null)[]>([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const lastDigitRef = useRef(0);
  const setColumnToNumber = (number: number) => {
    if (columnContainer.current && digitRef.current[number]) {
      setPosition(columnContainer.current.clientHeight * number);
      setWidth(`${(digitRef.current[number]?.offsetWidth || 0) + letterSpacing}px`);
    }
  };
  useEffect(() => setColumnToNumber(digit), [digit, letterSpacing]);
  useEffect(() => {
    if (isAnimating) return;
    setIsAnimating(true);
  }, [digit, delta]);
  return <div className={cn('ticker-column-container', 'relative font-medium font-mono h-auto')} ref={columnContainer} style={{
    fontSize,
    lineHeight: fontSize,
    color,
    width
  } as React.CSSProperties} data-sentry-component="NumberColumn" data-sentry-source-file="AnimatedCounter.tsx">
      <motion.div animate={{
      x: 0,
      y: position
    }} className={cn('ticker-column', 'absolute h-[1000%] bottom-0', {
      'animate-pulseGreen': delta === 'increase'
    }, {
      'animate-pulseRed': delta === 'decrease'
    })} onAnimationComplete={() => {
      setIsAnimating(false);
      lastDigitRef.current = digit;
    }} data-sentry-element="unknown" data-sentry-source-file="AnimatedCounter.tsx">
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map(num => <div key={num} className="ticker-digit w-auto h-[10%]">
            <span style={{
          fontSize,
          lineHeight: fontSize,
          color,
          width: calculateDigitWidth(num.toString())
        }} ref={el => (digitRef.current[num] = el) as any}>
              {num}
            </span>
          </div>)}
      </motion.div>
      <span className="number-placeholder invisible">0</span>
    </div>;
};
interface AnimatedCounterProps {
  value?: number;
  fontSize?: string;
  color?: string;
  includeDecimals?: boolean;
  letterSpacing?: number;
  showAs?: 'dollar' | 'percent';
}

// Main component
const AnimatedCounter: React.FC<AnimatedCounterProps> = ({
  value = 0,
  fontSize = '18px',
  includeDecimals = true,
  letterSpacing = 0,
  showAs = 'dollar'
}) => {
  const numArray = formatForDisplay(value, includeDecimals, showAs);
  const previousNumber = usePrevious(value);
  let delta: string | null = null;
  if (value > (previousNumber ?? 0)) delta = 'increase';
  if (value < (previousNumber ?? 0)) delta = 'decrease';
  const color = value > 0 ? '#bdf556' : '#ec605a';
  return <div className="animated-counter inline-flex" data-sentry-component="AnimatedCounter" data-sentry-source-file="AnimatedCounter.tsx">
      <motion.div layout className="ticker-view h-auto flex flex-row-reverse overflow-hidden relative" data-sentry-element="unknown" data-sentry-source-file="AnimatedCounter.tsx">
        {numArray.map((number, index) => isNaN(Number(number)) ? <DecimalColumn key={index} fontSize={fontSize} color={color} value={number} /> : <NumberColumn key={index} digit={Number(number)} delta={delta} fontSize={fontSize} color={color} letterSpacing={letterSpacing} showAs={showAs} />)}
      </motion.div>
    </div>;
};
export { AnimatedCounter };